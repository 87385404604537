<template>
  <div class="p-5">
    <div class="row">
      <div class="col-12">
        <h5>User details</h5>
      </div>
    </div>
    <hr/>
    <form class="mt-5" @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-4">
          <b><label class="mb-3">Name:</label></b>
          <div class="position-relative">
            <div v-if="canEdit" class="custom-icon small-icon pencil-icon icon-float-right"></div>
            <input
              v-model="form.name"
              placeholder="Full Name"
              :disabled="!canEdit"
              class="form-control mb-2"
            />
          </div>
          <span v-if="formErrors.name" class="invalid-field">{{ formErrors.name }}</span>
        </div>
        <div class="col-4">
          <b><label class="mb-3">Email:</label></b>
          <div class="position-relative">
            <div v-if="canEdit" class="custom-icon small-icon pencil-icon icon-float-right"></div>
            <input
              v-model="form.emailAddress"
              type="email"
              placeholder="Email"
              :disabled="!canEdit"
              class="form-control mb-2"
            />
          </div>
          <span v-if="formErrors.email" class="invalid-field">{{ formErrors.email }}</span>
        </div>
      </div>
    </form>
    <div v-if="canEdit" class="row my-5 justify-content-center">
      <button class="btn btn-secondary" @click="handleSubmit">Update Details</button>
    </div>
    <div class="row pt-5">
      <div class="col-12">
        <h5>Reset Password</h5>
      </div>
    </div>
    <hr/>
    <form class="mt-5" @submit="updatePassword">
      <div class="row">
        <div class="col-4">
          <b><label class="mb-3">Old Password:</label></b>
          <div class="position-relative">
            <div class="custom-icon small-icon pencil-icon icon-float-right"></div>
            <input
              v-model="formPassword.oldPassword"
              placeholder="*******"
              class="form-control"
              type="password"
            />
          </div>
          <span v-if="formErrors.oldPassword" class="invalid-field">{{ formErrors.oldPassword }}</span>
        </div>
        <div class="col-4">
          <b><label class="mb-3">New Password:</label></b>
          <div class="position-relative">
            <div class="custom-icon small-icon pencil-icon icon-float-right"></div>
            <input
              v-model="formPassword.newPassword"
              type="password"
              placeholder="*******"
              class="form-control"
            />
          </div>
          <span v-if="formErrors.newPassword" class="invalid-field">{{ formErrors.newPassword }}</span>
        </div>
        <div class="col-4">
          <b><label class="mb-3">Retype New Password:</label></b>
          <div class="position-relative">
            <div class="custom-icon small-icon pencil-icon icon-float-right"></div>
            <input
              v-model="formPassword.newPasswordConfirm"
              type="password"
              placeholder="*******"
              class="form-control"
            />
          </div>
          <span v-if="formErrors.newPasswordConfirm" class="invalid-field">{{ formErrors.newPasswordConfirm }}</span>
        </div>
      </div>
    </form>
    <div class="row my-5 justify-content-center">
      <button class="btn btn-primary" @click="updatePassword">Update Password</button>
    </div>
    <div style="margin-top: 12rem;">
    </div>
    <div class="page-footer">
      <div class="row justify-content-center">
        <router-link to="/user-management" class="btn btn-light justify-content-center">Back to home</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import * as uiActions from "../../store/modules/_shared/ui/actions";
import * as userActions from "../../store/modules/user/actions"
import * as authActions from "../../store/modules/auth/actions"

import { emailValidate } from "../../utils/helpers";
export default {
  name: "Profile",
  data(){
    return{
      currentUser: this.$store.getters.getUser,
      isDataLoading: false,
      canEdit: false,
      form: {
        name: "",
        emailAddress: ""
      },
      formPassword: {},
      formErrors: {}
    }
  },
  methods: {
    populateData() {
      this.form.name = this.currentUser.name
      this.form.emailAddress = this.currentUser.loginId
    },
    handleSubmit() {
      const user = {...this.$store.getters.user};
      user.emailAddress = this.form.emailAddress;
      user.name = this.form.name;
      if (this.checkDetailsForm()) {
        let postData = {
          userId: this.currentUser.userId,
          name: this.form.name,
          loginId: this.form.emailAddress,
        }
        this.$store.dispatch(userActions.USER_PROFILE_POST_REQUEST, postData).then(res => {
          if (res.status != 'ok') throw(res)
          this.$store.dispatch(uiActions.NOTIFICATION_SUCCESS, {
            message: "Details has been successfully updated!",
            timeOut: true
          });
          // Repopulate current user
          this.$store.commit(authActions.AUTH_SUCCESS, res.response)
          this.$router.push("/", { reload: true });
        }).catch(err => {
          console.log(err)
          this.$store.dispatch(uiActions.NOTIFICATION_ERROR, {
            message: err.errorMessage
          })
        })
      }
    },
    checkDetailsForm() {
      this.formErrors = {};
      let error = 0;

      if (!this.form.name) {
        this.formErrors.name = "Name is required";
        error++;
      } 
      if (!this.form.emailAddress) {
        this.formErrors.email = "Email is required";
        error++;
      } else {
        if(!emailValidate(this.form.emailAddress)){
          this.formErrors.email = "Email is invalid";
          error++;
        }
      }

      return !error;
    },
    updatePassword() {
      if (this.checkPasswordForm()) {
        const oldPassword = this.formPassword.oldPassword;
        const newPassword = this.formPassword.newPassword;
        const payload = {
          userId: this.currentUser.userId,
          oldPassword: oldPassword,
          newPassword: newPassword
        };
        this.$store.dispatch(authActions.PASSWORD_POST_REQUEST, payload).then(res => {
          if (res.status != 'ok') throw(res)  
          this.$store.dispatch(uiActions.NOTIFICATION_SUCCESS, {
            message: "Password has been successfully updated!",
            timeOut: true
          });
          this.formPassword = {};
          this.$router.push("/", { reload: true });
        }).catch(err => {
          console.log(err)
          this.$store.dispatch(uiActions.NOTIFICATION_ERROR, {
            message: err.errorMessage,
            timeOut: true
          });
        })
      }
    },
    checkPasswordForm() {
      this.formErrors = {};
      let error = 0;

      if (!this.formPassword.oldPassword) {
        this.formErrors.oldPassword = "Old password is required";
        error++;
      } 
      if (!this.formPassword.newPassword) {
        this.formErrors.newPassword = "New Password is required";
        error++;
      } 
      
      if(!this.formPassword.newPasswordConfirm){
        this.formErrors.newPasswordConfirm = "This field is required";
        error++;
      } else {
        if(this.formPassword.newPassword !== this.formPassword.newPasswordConfirm){
          this.formErrors.newPasswordConfirm = "New Password are not matched with this field";
          error++;
        }
      }

      return !error;
    },
  },
  mounted(){
    this.populateData()
    this.canEdit = this.currentUser.isAdmin
  }
}
</script>
<style scoped>
h5{
  cursor: default;
}
</style>